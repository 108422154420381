
import { defineComponent, onMounted, ref } from 'vue'
import router from '@/router';
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();

        onMounted(() => {
            const routeParams = router.currentRoute.value.params;
            window.localStorage.setItem("id_token", routeParams.token.toString());
    
            setTimeout(() => {
                // store.dispatch(Actions.CHECK_TOKEN);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }, 2500);
        })

        const loading = ref(true)

        const tableData = [
            {
                text: 'Loading',
            },
        ]

        return {
            loading,
            tableData
        }
    },
})
